import GA from 'springload-analytics.js';

import polyfill from './polyfills/polyfill-loader';
import onPageReady from './utils/onPageReady';

function initialise() {
    GA.init();

    /*  Mobile Menu  ------------------------ */
    const mobileNavEl = document.querySelector('.mobile-nav');
    if (mobileNavEl) {
        import(/* webpackChunkName: "mobile-nav" */ './modules/mobile.nav.js')
            .then(({ default: MobileNav }) => {
                new MobileNav(mobileNavEl);
            });
    }

    /*  Collapsible  ------------------------ */
    const collapsibleEls = document.querySelectorAll('[data-collapse-control], .collapse-control');
    if (collapsibleEls.length > 0) {
        import(/* webpackChunkName: `collapsible` */ './modules/collapsible.js')
            .then(({ default: collapsibleInit }) => {
                collapsibleInit([...collapsibleEls]);
            });
    }

    /* Trade Ticker Marquee ----------------- */
    const tradeTickerEl = document.querySelector('.desktop-ticker');
    if (tradeTickerEl) {
        import(/* webpackChunkName: "desktop-trade-ticker" */ './modules/desktop.trade.ticker.js')
            .then(({ default: tradeTicker }) => {
                tradeTicker();
            });
    }

    /* Mobile Trade Ticker ------------------ */
    const mobileTickerEl = document.querySelector('.mobile-ticker');
    if (mobileTickerEl) {
        import(/* webpackChunkName: "mobile-trade-ticker" */ './modules/mobile.trade.ticker.js')
            .then(({ default: MobileTradeTicker }) => {
                const thing = new MobileTradeTicker(mobileTickerEl);
                window.thing = thing;
            });
    }

    /* Carbon Trade Panel ----------------- */
    const carbonTraderEl = document.querySelector('.carbon-trader-wrapper');
    if (carbonTraderEl) {
        import(/* webpackChunkName: "carbon-trader" */ './modules/carbon.trader.js')
            .then(({ default: carbonTrader }) => {
                carbonTrader(carbonTraderEl);
            });
    }

    /* Auction Panel --------------------- */
    const auctionPanelEl = document.querySelector('.auction-panel-wrapper');
    if (auctionPanelEl) {
        import(/* webpackChunkName: "auction-panel" */ "./modules/auction.panel.js")
            .then(({ default: AuctionPanel }) => {
                new AuctionPanel(auctionPanelEl);
            });
    }
}

onPageReady(() => polyfill().then(initialise));
