/*
* This file should only be used for polyfills not supported by core-js
* Mostly this is Dom spec rather than ecmascript spec
* */

const fnAvailable = (fn) => typeof fn === 'function';

const targetFeaturesSupported = () => {
    return [
        fnAvailable(window.CustomEvent),
        fnAvailable(Element.prototype.closest),
        fnAvailable(window.fetch),
        fnAvailable(Object.assign),
        fnAvailable(Array.from),
        fnAvailable(String.prototype.startsWith),
    ].every(Boolean);
};

export default function polyfill() {
    return new Promise((resolve) => {
        if (targetFeaturesSupported()) {
            resolve();
        } else {
            import(/* webpackChunkName: "non-core-polyfills" */ './polyfills.js')
                .then(() => resolve());
        }
    });
}
